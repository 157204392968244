<template>
  <div class="simulated_fill_page">
    <div class="content">
      <div class="tip" v-if="piciInfo && piciInfo.point">
        <h2>修改报考批次</h2>
        <div class="sub_tip">报考批次影响各项推荐结果，建议选择推荐批次</div>
      </div>

      <ul class="pici_wrap">
        <li
          v-for="(item, index) in tableData"
          :key="index"
          @click="handleClick(item)"
        >
          <div>{{ item.key }}（{{ item.name }}）</div>
          <div class="pici_item">
            <span> 报考{{ item.key }}</span>
            <span class="checked_icon" v-if="activePici == item.key">
              <span class="el-icon-check"></span>
            </span>
          </div>
        </li>
      </ul>

      <div class="finish_select">
        <el-button type="primary" @click="confirmChangePici"
          >修改完成</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      tableData: [],
      piciInfo: null,
      scoreInfo: {},
      activePici: "",
      tuijian:"推荐"
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    })
  },
  watch: {
    userInfo: async function(newVal) {
      if (newVal && !this.piciInfo) {
        await this.getScorePiciTuijian();
        this.dealData();
      }
    }
  },
  async mounted() {
    if (this.userInfo) {
      await this.getScorePiciTuijian();
      this.dealData();
    }
  },
  methods: {
    dealData() {
      this.tableData = [];
      this.scoreInfo = {
        type: this.userInfo && this.userInfo.type,
        point: this.userInfo && this.userInfo.point
      };

      if (
        this.scoreInfo &&
        this.piciInfo &&
        Object.keys(this.piciInfo).length > 0
      ) {
        for (let key in this.piciInfo.detail) {
          let info = {
            key: key,
            default: false,
            name: "",
            btnShow: false
          };
          if (key == this.piciInfo.select_tag) {
            info.default = true;
          }
          let kongzhixian = "";
          for (let k in this.piciInfo.detail[key]) {
            if (
              k == this.scoreInfo.type &&
              this.scoreInfo.point - this.piciInfo.detail[key][k] >= 0
            ) {
              info.btnShow = true;
            }
            kongzhixian += k + " " + this.piciInfo.detail[key][k] + " / ";
          }
          if (key == "专科批") {
            info.btnShow = true;
          }
          info.name =
            kongzhixian.split("/")[0] + "/" + kongzhixian.split("/")[1];

          this.tableData.push(info);
        }
      }
    },
    async getScorePiciTuijian() {
      const apiUrl = "/api/province/data/query/";
      let params = {
        province: this.userInfo && this.userInfo.province,
        point: this.userInfo && this.userInfo.point,
        type: (this.userInfo && this.userInfo.type) || "",
        gap_point: this.userInfo && this.userInfo.gap_point
      };
      try {
        const res = await this.$axios.post(apiUrl, params);
        if (res.data.errno === 0) {
          const Data = res.data.results;
          localStorage.setItem("scoreInfoData", JSON.stringify(params));
          this.activePici = Data.select_tag;
          this.piciInfo = Data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleClick(item) {
      this.activePici = item.key;
      this.tuijian = "";
    },
    confirmChangePici() {
      this.$router.push({
        name: "SimulatedFill",
        params: { key: this.activePici }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.simulated_fill_page {
  height: 100vh;
  .content {
    padding: 0.45rem;
    height: 100vh;
    .tip {
      height: 1rem;
      margin-top: 0.3rem;
      margin-bottom: 0.8rem;
      h2 {
        font-size: 0.36rem;
      }
    }
    .pici_wrap {
      .pici_item {
        background: #fff;
        border-radius: 10px;
        padding: 0.25rem;
        margin: 0.2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .checked_icon {
          display: inline-block;
          width: 0.36rem;
          height: 0.36rem;
          background: #910000;
          border-radius: 50%;
          text-align: center;
          line-height: 0.36rem;
          .el-icon-check {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
    .el-table {
      border-radius: 3px;
      margin-top: 30px;
    }
  }
  .finish_select {
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
    padding: 0 0.45rem;
    .el-button {
      width: 100%;
    }
  }
}
</style>
